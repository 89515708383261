import React, { useEffect, useState } from "react";
import { getCookie } from "src/utils/helpers";
import arrowRightWhite from "../../../public/static/images/svg/arrow-right-white.svg";
import Router from "next/router";

type IAPProps = {
  ctaLink: string;
  infoCopy: string;
  alwaysMobile?: boolean;
  rawDataContract?: any;
  rawDataIAP?: any;
};

const IAP = ({
  ctaLink,
  infoCopy,
  alwaysMobile = false,
  rawDataContract
}: IAPProps) => {
  const [contractListData, setContractListData] = useState([]);
  const [haveCookie, setHaveCookie] = useState(false);

  const handleIAPNotif = () => {
    if (rawDataContract && rawDataContract.contracts?.length > 0) {
      setContractListData(
        rawDataContract.contracts.filter((data) => data.status === "APPROVED")
      );
    } else {
      if (localStorage.getItem("contract-list")) {
        const localStorageContractListData = JSON.parse(
          localStorage.getItem("contract-list")
        );
        const items = localStorageContractListData?.contracts?.filter(
          (data) => data.status === "APPROVED"
        );
        if (items?.length > 1) {
          Router.push("/dashboard/action");
        } else if (items?.length === 1) {
          window.location.replace(
            items[0].requestId && !items[0].isMistake
              ? `${process.env.clientZoneAuth}${items[0].requestId}`
              : `${process.env.credstoreAuth}${items[0].contractNumber}`
          );
        }
        setContractListData(items);
      }
    }
  };

  useEffect(() => {
    if (getCookie("rtoken") !== null) {
      handleIAPNotif();
      setHaveCookie(true);
    }
  }, []);

  return (
    haveCookie && (
      <>
        {contractListData && contractListData.length > 0 && (
          <div
            className={`iap-container ${
              alwaysMobile ? "iap-container-mobile" : ""
            }`}
          >
            <a
              href={ctaLink}
              className={`iap-wrapper ${
                alwaysMobile ? "iap-wrapper-mobile" : ""
              }`}
            >
              <div className="iap-notif-left">
                <div className="iap-notif-number-wrapper">
                  <div className="iap-notif-number-circle">
                    {contractListData.length}
                  </div>
                </div>
                <div className="iap-notif-copy-wrapper">{infoCopy}</div>
              </div>
              <div className="iap-notif-right">
                <div className="iap-notif-arrow">
                  <img src={arrowRightWhite} alt="arrow-right-white" />
                </div>
              </div>
            </a>
          </div>
        )}

        <style jsx>{`
          .iap-container {
            max-width: 940px;
            margin-right: auto;
            margin-left: auto;
            width: 100%;
            margin: 64px auto 48px;
          }
          .iap-wrapper {
            display: flex;
            justify-content: space-between;
            align-self: center;
            background-color: #3c91e6;
            border-radius: 5px;
            color: white;
            padding: 14px;
            align-items: center;
            margin-left: 15px;
            margin-right: 15px;
          }
          .iap-notif-left {
            display: flex;
            align-items: center;
          }
          .iap-notif-number-wrapper {
            margin-right: 16px;
          }
          .iap-notif-number-circle {
            border-radius: 50%;
            border: 8px solid #235689;
            padding: 3px;
            background-color: #3eac58;
            min-width: 23px;
            text-align: center;
            font-size: 14px;
          }
          .iap-notif-arrow {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .iap-notif-copy-wrapper {
            font-weight: bold;
          }
          .iap-wrapper-mobile {
            width: calc(100% - 60px);
            padding: 8px 16px;
            border-radius: 8px;
            margin: 32px 15px 24px;
          }
          .iap-container-mobile {
            margin: 32px auto 24px;
          }
          @media only screen and (max-width: 600px) {
            .iap-container {
              margin: 32px auto 24px;
            }
            .iap-wrapper {
              width: calc(100% - 60px);
              padding: 8px 16px;
              border-radius: 8px;
            }
            .iap-notif-number-circle {
              font-size: 13px;
              min-width: 22px;
            }
            .iap-notif-copy-wrapper {
              font-size: 12px;
            }
          }
        `}</style>
      </>
    )
  );
};

export default IAP;
