type ButtonProps = {
  text: string;
  radius?: string;
  padding?: string;
  onClick: () => void;
  dataCy?: string;
  fontWeight?: string;
  bgColor?: string;
  opacity?: boolean;
  fontSize?: string;
  fontColor?: string;
  fontSizeMobile?: string;
  widthMobile?: string;
  disabled?: boolean;
  maxWidth?: string;
  width?: string;
};

const Button = ({
  text,
  radius,
  padding,
  onClick,
  dataCy,
  bgColor,
  opacity,
  fontSize,
  fontColor,
  fontWeight,
  fontSizeMobile,
  widthMobile,
  disabled,
  maxWidth,
  width
}: ButtonProps) => {
  return (
    <>
      <button
        className={opacity ? "button-component button-opacity" : "button-component"}
        onClick={onClick}
        data-cy={dataCy}
        disabled={disabled}
      >
        {text}
      </button>
      <style jsx>{`
        .button-component {
          border: 2px solid var(--color-red);
          border-radius: ${radius || "5px"};
          background-color: ${bgColor ? bgColor : "var(--color-red)"};
          padding: ${padding ? padding : "8px 48px"};
          color: ${fontColor || "var(--color-white)"};
          font-size: ${fontSize ? fontSize : "16px"};
          font-weight: ${fontWeight || "normal"};
          cursor: pointer;
          width: ${width ? width : "auto"};
          max-width: ${maxWidth ? maxWidth : "none"}
        }
        .button-opacity {
          opacity: 0.7;
        }
        @media only screen and (max-width: 600px) {
          .button-component {
            font-size: ${fontSizeMobile ? fontSizeMobile : "14px"};
            padding: 8px 24px;
            width: ${widthMobile || "auto"};
          }
        }
        @media only screen and (max-width: 320px) {
          .button-component {
            font-size: 12px;
            padding: 8px 12px;
            width: ${widthMobile || "auto"};
          }
        }
      `}</style>
    </>
  );
};
export default Button;
